export const navMenu = [
  {
    name: "Mission",
    url: "#mission",
    pageName: "Blog",
  },
  {
    name: "Attend",
    url: "#attend",
    pageName: "Blog",
  },
  {
    name: "Foundation",
    url: "",
    pageName: "Blog",
  },
  {
    name: "News & Events",
    url: "",
    pageName: "Blog",
  },
  {
    name: "Contact",
    url: "#contact",
    pageName: "Blog",
  },
]
