import React, { useRef, useContext } from "react"
import { GlobalStyles } from "../components/layout/GlobalStyles"
import { Nav } from "../components/nav/Nav"
import { Footer } from "../components/Footer"
import { siteContext } from "./SiteContextProvider"

export const DefaultLayout = ({ children }) => {
  const { setDesktopActive, handleScroll } = useContext(siteContext)

  return (
    <div id="layout">
      <Nav />
      <GlobalStyles />
      <div onMouseEnter={() => setDesktopActive(false)}>
        {children}
        <Footer />
      </div>
    </div>
  )
}
