import styled from "@emotion/styled"
import { Link } from "gatsby"
import React, { useContext, useCallback, useState, useEffect } from "react"
import { StaticImage } from "gatsby-plugin-image"
// import { Burger } from "./Burger"
import { navMenu } from "./NavMenu"
import { colors, fonts, device } from "../layout/GlobalStyles"
import { siteContext } from "../../layout/SiteContextProvider"
import { Button } from "../layout/StyledComponents"
import greenArrow from "../../images/icons/arrow-green.svg"
import redArrow from "../../images/icons/arrow-red-yellow.svg"
import closeRed from "../../images/close-red.svg"

const StyledNav = styled.nav`
  z-index: 1000;
  padding-top: 10px;
  position: fixed;
  top: 0em;
  width: 100%;
  .logo {
    height: 26px;
    width: 80px;
    transition: opacity 0.2s ease-in;
    &:hover {
      opacity: 0.7;
      transition: opacity 0.2s ease-in;
    }
  }
  @media ${device.laptop} {
    transition: height 0.2s ease-out;
    transition: margin 0.2s ease-out;
    height: ${({ navDesktopNavOpacity }) =>
      navDesktopNavOpacity ? "85px" : "120px"};
    margin: ${({ navDesktopNavOpacity }) =>
      navDesktopNavOpacity ? "-1.5em 0em" : ""};
    box-shadow: ${({ navDesktopNavOpacity }) =>
      navDesktopNavOpacity ? "0 0 20px rgb(0 0 0 / 20%)" : "none"};
    background-color: ${({ navDesktopNavOpacity }) =>
      navDesktopNavOpacity ? "rgba(255, 250, 241, 0.9)" : "none"};
    top: 0em;
    display: flex;
    width: 100vw;
  }
`

const MobileMenuSlide = styled.div`
  position: fixed;
  width: 100vw;
  min-height: ${({ mobileActive }) =>
    mobileActive ? "0vh" : "-webkit-fill-available"};
  bottom: 0;
  top: 0;
  z-index: 1000;
  background: ${colors.secondary302};
  transition: transform 0.2s ease-out;
  transform: ${({ mobileActive }) =>
    mobileActive ? "translateY(00%)" : "translateY(120%)"};
  .logo-white {
    width: 94px;
    margin-top: 4em;
  }
  /* list-container */
  .slider-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .exit-button {
    /* change X TRANSFORM when navmenu is active */
    z-index: 0;
    border: none;
    width: 75px;
    height: 75px;
    transition: 0.3s ease-in;
    opacity: ${({ mobileActive }) => (mobileActive ? "1" : "0")};
    border-radius: 8px 0px 0px 0px;
    background: ${colors.secondary201};
    position: absolute;
    right: 0em;
    bottom: 0em;
  }
  .list-container {
    height: 45vh;
    margin-top: 6em;
    position: relative;
    ul {
      padding: 0em;
      list-style: none;
      font-family: ${fonts.primary};
      .active {
        color: ${colors.secondary202};
      }
      li,
      a {
        color: ${colors.white};
        text-transform: uppercase;
        font-weight: 800;
        font-size: 30px;
        line-height: 32px;
        margin: 0.4em 0em;
        &:hover {
          color: #c93239;
        }
      }
    }
  }
`

const MobileMenu = styled.nav`
  z-index: 1000;
  display: block;
  left: 0;
  width: 100vw;
  background: ${colors.secondary302};
  bottom: 0;
  height: 60px;
  position: fixed;
  transform: translate3d(0, 0, 0);
  ul {
    padding: 0.5em 0em;
    list-style: none;
    display: flex;
    justify-content: center;
    li,
    button {
      font-family: ${fonts.primary};
      font-weight: 800;
      color: ${colors.white};
      margin: 0em 0.6em;
      font-size: 1em;
      padding: 0em;
    }
    li button {
      margin: 0em;
    }
  }
  @media ${device.laptop} {
    display: none;
  }
`

const DesktopMenu = styled.div`
  display: none;
  @media ${device.laptop} {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 96px;
    max-width: 1112px;
    margin: 0 auto 74px;
    align-items: center;
    z-index: 3;
    ul {
      list-style: none;
      display: flex;
      margin: 0em;
      padding: 0em;
      justify-content: space-between;
      width: 800px;
      button {
        background: none;
        border: none;
        padding: 0em;
        font-size: 20px;
        font-weight: 900;
        color: ${colors.secondary302};
        font-family: ${fonts.primary};
        display: flex;
        align-items: center;
        &:hover {
          color: ${colors.primary2};
        }
      }

      .green {
        color: ${colors.primary2};
      }
      li {
        cursor: pointer;
        color: ${colors.secondary302};
        margin: 0.83em;
        font-size: 20px;
        font-weight: 900;
        display: flex;
        align-items: center;
        font-family: ${fonts.primary};
        .rotate {
          -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
          transition: transform 0.1s ease-in;
        }
        .unrotate {
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
          transition: transform 0.1s ease-in;
        }
        /* &:hover img {
      
        }
        &:not(:hover) img {
          transition: transform 0.1s ease-in;
          transform: rotate(00deg);
        } */
        img {
          img {
          }
          position: relative;
          left: 0.25em;
        }
      }
    }
  }
`

const DesktopDropDownMenu = styled.div`
  display: none;
  @media ${device.tablet} and (orientation: landscape) {
    background: ${colors.secondary201};
    .menu-container {
      position: relative;
      width: 600px;
      margin: 0 auto;
    }
    ul {
      position: absolute;
      top: 85px;
      left: ${({ clickedIndex }) => clickedIndex === 0 && "0px"};
      left: ${({ clickedIndex }) => clickedIndex === 1 && "58px"};
      left: ${({ clickedIndex }) => clickedIndex === 3 && "470px"};
      left: ${({ clickedIndex }) => clickedIndex === 4 && "685px"};
      list-style: none;
      padding: 0;
      display: flex;
      flex-direction: column;
      li {
        a {
          cursor: pointer;
          font-family: ${fonts.primary};
          font-weight: 600;
          font-size: 22px;
          letter-spacing: -0.69px;
          line-height: 32px;
          color: ${colors.secondary302};
          &:hover {
            color: ${colors.primary2};
          }
        }
      }
    }
    transition: transform 0.6s cubic-bezier(0.33, 1, 0.68, 1);
    /* transition-timing-function: cubic-bezier(0.1, 0.7, 1, 0.1); */
    transform: ${({ desktopActive }) =>
      desktopActive ? "translateY(000%)" : "translateY(-100%)"};
    width: 100vw;
    box-shadow: 0 0 20px rgb(0 0 0 / 20%);
    display: block;
    height: 360px;
    position: fixed;
    top: 0em;
    z-index: 2;
    ul {
      opacity: ${({ desktopActive }) => (desktopActive ? "1" : "0")};
      transition-delay: 2s;
      transition-property: opacity;
      transition: opacity 2s cubic-bezier(0.33, 1, 0.68, 1);
    }
  }
`

const debounce = (func, ms) => {
  let timer
  return (...args) => {
    if (!timer) {
      timer = setTimeout(() => {
        timer = undefined
        func.apply(this, args)
      }, ms)
    }
  }
}

export const Nav = () => {
  const { mobileActive, setMobileActive } = useContext(siteContext)
  const { desktopActive, setDesktopActive } = useContext(siteContext)
  const [stateIndex, setStateIndex] = useState(null)
  const isSSR = typeof window === "undefined"
  const [position, setPosition] = useState(isSSR ? 0 : window.scrollY)
  const [navDesktopNavOpacity, setNavDesktopNavOpacity] = useState(false)
  const [menu, setMenu] = useState("")
  const [clickedIndex, setClickedIndex] = useState(null)

  const debounceMs = 200

  const onScroll = useCallback(
    debounce(() => setPosition(window.scrollY), 300),
    [debounceMs, setPosition]
  )

  useEffect(() => {
    if (isSSR) return
    window.addEventListener("scroll", onScroll)
    if (position > 100) {
      setNavDesktopNavOpacity(true)
    } else {
      setNavDesktopNavOpacity(false)
    }
    return () => {
      window.removeEventListener("scroll", onScroll)
    }
  }, [position])
  return (
    <>
      <MobileMenu>
        <ul>
          <Link to="/purpose/">
            <li>Purpose</li>
          </Link>
          <Link to="/programs/">
            <li>Programs</li>
          </Link>
          <Link to="/people/">
            <li>People</li>
          </Link>
          <li>
            <button
              style={{ border: "none", background: "none" }}
              onClick={() => setMobileActive(true)}
            >
              More{" "}
            </button>
          </li>
        </ul>
      </MobileMenu>
      <StyledNav
        desktopActive={desktopActive}
        navDesktopNavOpacity={navDesktopNavOpacity}
      >
        <DesktopMenu>
          <Link to="/">
            <button
              style={{ background: "none", border: "none" }}
              onClick={() => setMenu(null)}
            >
              <StaticImage
                className="X logo"
                src="../../images/logos/logo-regular.svg"
                placeholder="none"
                quality={100}
                alt="MLIFE logo"
              />
            </button>
          </Link>
          <ul>
            {navMenu.map((navItem, index) => {
              if (navItem.name === "Foundation") {
                return (
                  <li key={navItem.name}>
                    <a
                      rel="noopener noreferrer"
                      href="https://www.mlifefoundation.org/"
                      target="__blank"
                    >
                      <button
                        // className={
                        //   (stateIndex === index && desktopActive && `green`) ||
                        //   (menu &&
                        //     menu.pageName === navItem.pageName &&
                        //     "green")
                        // }
                        onMouseEnter={() => {
                          setStateIndex(index)
                        }}
                        onMouseLeave={() => {
                          !desktopActive && setStateIndex(null)
                        }}
                        onClick={() => {
                          if (!navItem.subMenu) {
                            setMenu(navItem)
                            setDesktopActive(false)
                            return
                          }
                          setMenu(navItem)
                          setClickedIndex(index)
                          setStateIndex(index)
                          index === stateIndex
                            ? setDesktopActive(active => !active)
                            : setDesktopActive(true)
                        }}
                      >
                        {navItem.name}
                        {navItem.subMenu && index === stateIndex && (
                          <img
                            className={`${
                              desktopActive ? "rotate" : "unrotate"
                            }`}
                            src={greenArrow}
                            alt="dropdown-menu"
                          />
                        )}
                        {navItem.subMenu && index !== stateIndex && (
                          <img src={redArrow} alt="dropdown-menu" />
                        )}
                        {!navItem.subMenu && <></>}
                      </button>
                    </a>
                  </li>
                )
              }
              if (navItem.name === "News & Events") {
                return (
                  <li key={navItem.name}>
                    <a
                      rel="noopener noreferrer"
                      href="https://www.mlifefoundation.org/news-events/"
                      target="__blank"
                    >
                      <button
                        // className={
                        //   (stateIndex === index && desktopActive && `green`) ||
                        //   (menu &&
                        //     menu.pageName === navItem.pageName &&
                        //     "green")
                        // }
                        onMouseEnter={() => {
                          setStateIndex(index)
                        }}
                        onMouseLeave={() => {
                          !desktopActive && setStateIndex(null)
                        }}
                        onClick={() => {
                          if (!navItem.subMenu) {
                            setMenu(navItem)
                            setDesktopActive(false)
                            return
                          }
                          setMenu(navItem)
                          setClickedIndex(index)
                          setStateIndex(index)
                          index === stateIndex
                            ? setDesktopActive(active => !active)
                            : setDesktopActive(true)
                        }}
                      >
                        {navItem.name}
                        {navItem.subMenu && index === stateIndex && (
                          <img
                            className={`${
                              desktopActive ? "rotate" : "unrotate"
                            }`}
                            src={greenArrow}
                            alt="dropdown-menu"
                          />
                        )}
                        {navItem.subMenu && index !== stateIndex && (
                          <img src={redArrow} alt="dropdown-menu" />
                        )}
                        {!navItem.subMenu && <></>}
                      </button>
                    </a>
                  </li>
                )
              } else {
                return (
                  <li key={navItem.name}>
                    <Link to={`${navItem.url}`}>
                      <button
                        // className={
                        //   (stateIndex === index && desktopActive && `green`) ||
                        //   (menu &&
                        //     menu.pageName === navItem.pageName &&
                        //     "green")
                        // }
                        onMouseEnter={() => {
                          setStateIndex(index)
                        }}
                        onMouseLeave={() => {
                          !desktopActive && setStateIndex(null)
                        }}
                        onClick={() => {
                          if (!navItem.subMenu) {
                            setMenu(navItem)
                            setDesktopActive(false)
                            return
                          }
                          setMenu(navItem)
                          setClickedIndex(index)
                          setStateIndex(index)
                          index === stateIndex
                            ? setDesktopActive(active => !active)
                            : setDesktopActive(true)
                        }}
                      >
                        {navItem.name}
                        {navItem.subMenu && index === stateIndex && (
                          <img
                            className={`${
                              desktopActive ? "rotate" : "unrotate"
                            }`}
                            src={greenArrow}
                            alt="dropdown-menu"
                          />
                        )}
                        {navItem.subMenu && index !== stateIndex && (
                          <img src={redArrow} alt="dropdown-menu" />
                        )}
                        {!navItem.subMenu && <></>}
                      </button>
                    </Link>
                  </li>
                )
              }
            })}
          </ul>
          <Link to="/donate/">
            <Button color="green">Donate</Button>
          </Link>
        </DesktopMenu>
        <DesktopDropDownMenu
          clickedIndex={clickedIndex}
          desktopActive={desktopActive}
        >
          <div className="menu-container">
            <ul>
              {menu &&
                menu.subMenu &&
                menu.subMenu.map((menuItem, index) => {
                  return (
                    <li
                      onClick={() => {
                        setMenu(menuItem)
                        setDesktopActive(false)
                      }}
                    >
                      <Link to={menuItem.url}>{menuItem.name}</Link>
                    </li>
                  )
                })}
            </ul>
          </div>
        </DesktopDropDownMenu>
        {/* <Burger className="burger" /> */}
        <MobileMenuSlide mobileActive={mobileActive}>
          <div className="slider-container">
            <button
              style={{ background: "none", border: "none" }}
              onClick={() => setMobileActive(false)}
            >
              <Link to="/">
                <StaticImage
                  className="logo-white"
                  src="../../images/logos/logo-foundation-white.svg"
                  placeholder="none"
                  quality={100}
                  alt="MLIFE logo"
                />
              </Link>
            </button>
            <div className="list-container">
              <ul>
                {navMenu.map(navItem => {
                  return (
                    <li key={navItem.name}>
                      <Link
                        onClick={() => setMobileActive(false)}
                        to={navItem.url}
                      >
                        {navItem.name}
                      </Link>
                    </li>
                  )
                })}
              </ul>
            </div>
            <button
              aria-label="exit button"
              className="exit-button"
              onClick={() => setMobileActive(false)}
            >
              <img src={closeRed} alt="close" />
            </button>
          </div>
        </MobileMenuSlide>
      </StyledNav>
    </>
  )
}
